<template>
  <v-dialog v-model="dialog" persistent width="680">
    <v-card tile>
      <v-card-title v-if="formatFlag" class="pl-4">Adicionar Formato - {{ formatFlag.toUpperCase() }}</v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-alert v-if="duplicatedKeys" class="mx-4" dense type="error" outlined>Já existe um formato configurado com a mesma chave.</v-alert>
        <v-row no-gutters class="px-4 pt-2">
          <v-col cols="5">
            <v-text-field v-model="newField.key" :rules="stringRules" class="mr-2" label="Chave" outlined />
          </v-col>
          <v-col cols="7">
            <v-text-field v-model="newField.value" :rules="stringRules" label="Valor" outlined />
          </v-col>
        </v-row>
      </v-form>
      <v-divider />
      <btn-save-cancel :btn-outline="[true, false]" :btn-size="225" props-class="pa-4" @onSave="saveManager" @onCancel="cleanFields" />
      <change-dialog :dialog.sync="changesDialog" :message.sync="changesMessage" @proceed="addField" />
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_ADD_FORMAT } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnSaveCancel: () => import('@/components/base/BtnSaveCancel'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    accountId: String,
    dialog: {
      type: Boolean,
      default: false
    },
    formats: {
      type: Array,
      default: () => []
    },
    formatFlag: {
      type: String,
      default: ''
    },
    isDeploying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false,
    duplicatedKeys: false,
    stringRules: [v => !!v || 'Campo obrigatório'],
    newField: {
      key: '',
      value: ''
    },
    changesDialog: false,
    changesMessage: ''
  }),
  methods: {
    async addField() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Erro ao validar campos', snackbarColor: '#F44336' })

      if (this.formats.includes(this.newField.key)) {
        this.duplicatedKeys = true
        return
      }
      this.$setLogMessage(this.changesMessage)

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_ADD_FORMAT,
          variables: {
            id: this.accountId,
            flag: this.formatFlag,
            key: this.newField.key,
            value: this.newField.value
          }
        })

        this.$snackbar({ message: 'Novo formato adicionado', snackbarColor: '#2E7D32' })
        this.$emit('close')
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao inserir novo formato', snackbarColor: '#F44336' })
      }
      this.cleanFields()
    },
    cleanFields() {
      this.newField.key = ''
      this.newField.value = ''
      this.duplicatedKeys = false
      this.$emit('close')
    },
    saveManager() {
      if (this.isDeploying) return this.addField()
      this.changesDialog = true
    }
  }
}
</script>
